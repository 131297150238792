@import "~react-md/dist/react-md";
@import './libs/react-mpk/styles/mpk.scss';
@import '~@mdi/font/scss/materialdesignicons.scss';
@import '~material-icons/iconfont/material-icons.css';
@import '~izitoast/dist/css/iziToast.min.css';
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');
@import '../node_modules/storm-react-diagrams/dist/style.min.css';

@include react-md-utils;

$default-font: 'Source Sans Pro', sans-serif;
$decorative-font: 'Roboto Slab', serif;
$body-font: 'Open Sans', serif !important;
 
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $default-font;
  // background-color: #eeebe7;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $default-font;
}


#root {
  width: 100%;
  height: 100%;
}

.decorative-text {
  font-family: $decorative-font !important;
}

.body-text {
  font-family: $body-font;
  font-weight: 400;

  > * {
    font-family: $body-font;
    font-weight: 400;
  }
}

// --------DIAGRAM--------/
.srd-diagram {
  width: 100%;
  min-height: 400px;
}

.mpk-bluepring-bg {
  background: url('./assets/bluepring-bg.jpg')
}
// -------- END OF DIAGRAM--------/

.rmd-tab {
  height: 60px;
  text-transform: none;
}

.pagination-container {
  background: white;
}

// -------- START OF NEW UI--------/

.new-table-wrapper .rmd-table-container {
  height: 100% !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.new-table-wrapper table {
  border-collapse: separate !important;
  border-spacing: 0px 12px !important;
  padding: 24px;
}

.new-table-wrapper table tbody tr {
  background: white !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.new-table-wrapper table tbody tr td {
  padding: 16px;
  height: 80px !important;
}

.new-table-wrapper table tbody tr td:first-child {
  padding: 16px;
  border: 0px solid white !important;
  box-shadow: none !important;
}

.new-table-wrapper table thead tr td {
  padding: 0px 16px;
}

.new-table-wrapper table tbody tr td:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.new-table-wrapper table tbody tr td:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

// .new-table-wrapper .rmd-table-cell--no-wrap:first-child {
//   border-radius: 8px;
// }

// .new-table-wrapper .rmd-table-cell--no-wrap:last-child {
//   border-radius: 8px;
// }


.new-table-wrapper .mpk-data-table .rmd-table {
  // background: white !important;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  // border-radius: 8px;
}

.new-table-wrapper-checkbox table tbody tr {
  box-shadow: none !important;
}

.new-table-wrapper .rmd-table-cell--sticky {
  background: none !important;
  border-spacing: 0px 8px;
}

.pagination-container {
  z-index: 10;
}

// .mpk-commandbar {
//   height: 128px !important;
// }

.new-form-wrapper .mpk-commandbar {
  height: 64px !important;
}

.new-table-wrapper-title {
  font-size: 32px;
  margin-bottom: 0px;
  margin-top: 18px;
  font-weight: 600;
}

.new-table-wrapper-subtitle {
  font-size: 18px;
  font-weight: 400;
  color: grey;
  margin-top: 0px;
}

// To be Updated new style
// .rmd-icon {
// 	margin: 10px;
// }

.rmd-list {
  padding: 0px;
}

.table-header-with-tabs {
  width: 100% !important;
  padding-bottom: 20px; 
  margin-top: -20px,
}

.top-content-tabs {
  width: 100% !important;
  background: white;
}

.new-form-wrapper-none .mpk-paper {
  background: none !important;
}

.new-form-wrapper-none .mpk-commandbar {
  height: 64px !important;
}

.menu-icon {
  position: static;
  width: 67px;
  height: 70px;
  left: 0px;
  top: 0px;
  display: flex;
  margin-right: 0px;
}

.menu-list {
  pointer-events: auto;
  cursor: pointer;
  padding: 0px 16px 0px 0px;
  position: static;
  width: 312px;
  height: 70px;
  left: 24px;
  top: 144px;
  background: #FFFFFF;
  /* Dark / Dark 0 */

  border: 1px solid #1C1C28;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  text-align: left;
}

.heading-3 {
  margin: 5px 0px;
}

.rata-kiri-text {
  margin-left: 0;
  /* identical to box height */
  align-items: center;
  letter-spacing: 0.004em;
}

.rata-kiri-sub{
  align-items: center;
  letter-spacing: 0.0025em;
  color: #555770;
}

.rata-kiri {
  margin-left: 0;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.004em;

  /* Dark / Dark 2 */

  color: #555770;
}

.sidebar-spt {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;
}

.sidebar-spt::-webkit-scrollbar {
  display: none;
}

.calender-spt {
  width: 312px !important;
  text-align: center;
  letter-spacing: 0.004em;

  /* Dark / Dark 2 */

  color: #555770;

  
  background: #FFFFFF;
  /* Dark / Dark 0 */

  border: 1px solid #1C1C28;
  box-sizing: border-box;
  /* Light/Elevation 06 */

  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 5px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
  margin-bottom: 0px;
}

.today-calender {
  background: #555770;
  border-radius: 40px;
  color : #ffffff  !important;
}

.today-calender::after {
  visibility: hidden; /* hide small border under the text */
}

.select-month-custom{
  position: static;
  width: 80px;
  height: 40px;
  left: calc(50% - 80px/2 - 50px);
  top: calc(50% - 40px/2);
  background: #E4E4EB;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  font-weight: 600;
  color: #555770;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.select-year-custom{
  position: static;
  width: 80px;
  height: 40px;
  left: calc(50% - 80px/2 - 50px);
  top: calc(50% - 40px/2);
  background: transparent;
  border-radius: 8px;
  border: transparent;
  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  font-weight: 600;
  color: #555770;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.custom-headers{
  width: 310px !important;
}

.react-datepicker__navigation {
  border: 0;
}

.day-custom {
  text-align: center;
  letter-spacing: 0.004em;

  /* Dark / Dark 2 */

  color: #555770;
}

.week-custom {
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #555770;
}

.navigation-date{
  margin: 8.33%;
  background: #555770;
  color: white;
  border : 0;
  border-radius: 50%;
}

.date-list {
  margin: 0px;
}

.drop-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 10px;
  width: 130px;
  height: 40px;
  background: #555770;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-height: 182px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding: 30px 0px;
  border: 2px dashed #858C94;
  background-color: white !important;
  color: #555770;
  outline: none;
  transition: border .24s ease-in-out;
}


.dzu-dropzoneActive {
  background-color: #DEEBFF;
  border-color: #8f90a6;
}


.dzu-dropzoneDisabled {
  opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #c7c9d9;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  background-color: #E6E6E6;
  color: #c7c9d9;
  border: none;
  font-family: 'Helvetica', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}


.dzu-previewStatusContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.dzu-previewImage {
  width: auto;
  max-height: 40px;
  max-width: 140px;
  border-radius: 4px;
}

.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}

.dzu-submitButtonContainer {
  margin: 24px 0;
  z-index: 1;
}

.dzu-submitButton {
  position: absolute;
  right:    25px;
  bottom:   20px;
  padding: 10px 10px;
  /* Dark / Dark 2 */
  width: 130px;
  height: 40px;
  /* Light/Elevation 04 */
  background: white;
  color: #555770;
  text-transform: capitalize;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  font-family: Roboto;

  margin-right: 12px;
  outline-color: #555770;
  color: #8F90A6;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-submitButton:disabled {
  background-color: #E6E6E6;
  color: #8F90A6;
  cursor: unset;
}

.dzu-error{
  color: red;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.2fr 3fr;
  grid-template-rows: 40px 40px;
  grid-template-areas: 
        "menu main"
        "menu footer";
  grid-gap: 0px;
  padding: 0px;
}

.btn-import-outline {
  min-width: 92px;
  color: #555770;
  text-transform: capitalize;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);

  margin-right: 12px;
  outline-color: #555770;
  border: 1px solid #555770;
  height: 32px;
}

.btn-import-contained {
  color: white;
  background: #555770;
  text-transform: capitalize;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 8px 10px;
  min-width: 92px;
  height: 32px;
}

.btn-template {
  height: 32px;
  left: 0px;
  top: 0px;
  text-transform: capitalize;
  background: #C7C9D9;
  color :#28293D;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 5px;
  min-width: 92px;
}

.text-small {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
}

.text-medium {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.004em;
  line-height: 24px;
  font-family: Roboto;
  font-style: normal;
  color:'#555770'
}

.text-large {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.004em;
  line-height: 24px;
  font-family: Roboto;
  font-style: normal;
}

.dialog-custom {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  bottom: 10px;
}

.dialog-text-custom {
  color:'#555770';
  text-align: center;
}

.dialog-header-custom {
  text-align: start;
  padding-bottom: 0;
}

.import-header {
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;
  color: #1C1C28;
}

.filter__control {
  height: 56px;
}

.filter__value-container {
  height: 56px;
}

.react-select-label {
  top: -11px !important
}

.react-select-material {
  margin-top: -20px !important;
  margin-bottom: 0px !important;
}

.react-datepicker-popper {
  z-index: 40 !important;
}

.rmd-table-container {
  background: white !important;
}

.divider-custom {
  border-bottom: 1px solid #C9C9C9;
  padding-bottom: 5px;
  color: #8F90A6;
}

.dzu-dropzone {
  overflow: auto !important;
}

.mpk-full {
  z-index: 0 !important;
}
// -------- END OF NEW UI--------/

// -------- E-METERAI UI --------/

html {
  overflow: hidden !important;
}

.mpk-appbar {
  height: 80px !important;
  z-index: 1 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.mpk-appbar .mpk-picture .mdi-account {
  margin: 8px !important;
}

.mpk-sidebar {
  width: 300px !important;
  background: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.mpk-form-wrapper {
  // padding: 24px 48px !important;
  padding: 0px 14px;
}

.mpk-form-wrapper.mpk-paper {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.menu-group > div:first-child {
  display: none !important;
}

.mpk-menu > div:first-child {
  display: none !important;
}

.mpk-menu > .scrollable  {
  margin-top: 12px !important;
}

.mpk-menu .menus .menu-item {
  border-top: 0px !important;
  // font-size: larger !important;
  // margin: 48px 12px !important
  font-size: medium !important;
  margin: 5px 12px !important
}

.mpk-menu .menus .menu-item:hover {
  // background: red !important;
  padding: 12px;
  border-radius: 12px;
}

.mpk-menu .menus .menu-item:visited {
  // background: red !important;
  padding: 12px;
  border-radius: 12px;
}

.mpk-menu .menus .menu-item:link {
  // background: red !important;
  padding: 12px;
  border-radius: 12px;
}

.mpk-menu .menu-group {
  border-bottom: 0px !important;
}

.mpk-menu .menus .rmd-icon {
  font-size: 24px !important;
}

.mpk-menu .menus .menu-item.active {

}

.full-width {
  width: 100% !important;
}

.dashboard-card {
  width: 25%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 8px;
  min-height: 130px;
  background: white;
}

.dashboard-card-icon {
  font-size: 50px;
  padding: 40px 15px 30px 30px;
  color: slategrey;
  float: left;
}

.dashboard-card-text {
  margin: 40px;
}

.dashboard-card-title {
  color: 'grey';
  font-size: 16px;
  margin: 0px;
}

.dashboard-card-subtitle {
  color: #DD3F34;
  font-weight: 700;
  font-size: 24px;
  margin: 0px;
}

.dashboard-card-info {
  float: right;
  margin: 12;
}

.step-card {
  width: 33.3%;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 12px;
  min-height: 0px;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
}

.step-card-icon {
  font-size: 50px;
  padding: 20px 15px 20px 20px;
  color: slategrey;
  float: left;
  margin-left: 33.3%;
}

.step-card-text {
  margin: 20px;
  text-align: left;
}

.step-card-title {
  color: 'grey';
  font-size: 16px;
  margin: 0px;
  text-align: left;
}

.step-card-subtitle {
  color: #DD3F34;
  font-weight: 700;
  font-size: 24px;
  margin: 0px;
  text-align: left;
}

.step-card-info {
  float: right;
  margin: 12;
}

.rmd-tabs {
  position: block !important;
}

.tabs-bar-item {
  font-size: 18px;
  padding: 18px 60px;
  font-weight: 600;
}

.tabs-bar-item:hover {
  border-bottom: 4px solid darkgrey;
}

.tabs-bar-item.active {
  border-bottom: 4px solid grey;
}

.e-meterai-table .mpk-commandbar {
  background: none !important;
  padding: 24px !important;
}

// .e-meterai-table .rmd-table-cell--sticky-above {
//   z-index: 1 !important;
// }

// .rmd-table-cell--sticky-above {
//   z-index: 1 !important;
// }

// .rmd-table-cell--sticky {
//   z-index: 1 !important;
// }

.e-meterai-table {
  padding: 24px 48px 0px 48px !important;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

// -------- CAROUSEL -----------/
.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.left-arrow, .right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow, .right-arrow {
      display: none;
  }
}


// ----- NEW TYPOGRAPHY ---------------/
.text-heavy {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.text-medium {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text-medium-normal{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.text-small {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
}
// ----- NEW TYPOGRAPHY ---------------/


// -------- DRAG & DROP COMPONENT -----------/

.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 182px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding: 30px 0px;
  border: 2px dashed #858C94;
  background-color: white !important;
  color: #555770;
  outline: none;
  transition: border .24s ease-in-out
}

.dzu-dropzoneActive {
  background-color: #DEEBFF;
  border-color: #8f90a6;
}

.dzu-previewContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex;
  justify-content: space-between;
  position: relative;
  width: 125px;
  min-height: 60px;
  z-index: 1;
  box-sizing: border-box;
}

.dzu-btn-clear {
  background: #FFFFFF;
  width: 124px;
  height: 40px;
  border: 1px solid #545D69;
  box-sizing: border-box;
  padding: 8px 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  text-transform: capitalize;
  color : #545D69;
  margin: 5px;
}

.dzu-btn-submit {
  background: #BC2020;
  width: 124px;
  height: 40px;
  border: none;
  box-sizing: border-box;
  padding: 8px 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  text-transform: capitalize;
  color: white;
  margin: 5px;
}

.dzu-error{
  color: red;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &--prev {
      left: -10rem;
  }

  &--next {
      right: -10rem;
  }
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;

  &--left {
      transform: rotate(135deg);
  }

  &--right {
      transform: rotate(-45deg);
  }
}

progress {
  width: 125px;
  height: 36px;
  left: 0px;
  right: 0px;
  top: 109.21px;
  background: #1e2022;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}

progress:before {
  content: attr(data-label);
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  left: 40%;
  top: 4px;
  line-height: 20px;
  position:absolute;
  white-space: nowrap;
  overflow: hidden !important;
}

progress::-webkit-progress-bar {
  background-color: #dadee3;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress::-webkit-progress-value {
  background: #545D69;
  border-radius: 2px; 
  background-size: 35px 20px, 100% 100%, 100% 100%;
  transition: width 0.5s ease-in-out;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.2fr 3fr;
  grid-template-rows: 40px 40px;
  grid-template-areas: 
        "menu main"
        "menu footer";
  grid-gap: 0px;
  padding: 0px;
}


.drop-title {
  color : #09101D;
  text-align: center;
}

.dzu-text-or{
  position: static;
  width: 12px;
  height: 20px;
  left: 109px;
  top: 44px;
  color: #09101D;
  text-align: center;
  margin: 10px 0;
}

.dzu-title-icon {
  margin: 10px;
  font-size: 16px;
  color: #09101D;
  font-weight: bolder;
}

.btn-new {
  background: #BC2020;
  color: white;
  width: 48px;
  height: 48px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 8px 10px;
  display: inline;
  margin: 0 10px;
}

.div-import-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  min-height: 0;
  background-color: transparent;
  border: none;
  font-family: 'Helvetica', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.btn-delete {
  cursor: pointer;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  left: 2.5px;
  right: 102.5px;
  top: -0.01px;
  font-size: 15px;
  color: #000;
}

.dzu-preview-img {
  left: 7px;
  padding: 35px 35px;
  position: absolute;
  background: #DADEE3;
  border-radius: 5px;
  text-align: center;
  font-size: 40px;
  color: #000;
}

.alice-carousel {
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: auto;
  direction: inherit; 
  left: 0px;
  right: 0px;
  text-align: center;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  min-height: 170px;
}

.alice-carousel__stage {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(11, auto);
  grid-auto-rows: minmax(150px, max-content);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; }
  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 35px 5px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0; }
    .alice-carousel__stage-item * {
      line-height: initial; }
    .alice-carousel__stage-item.__hidden {
      opacity: 0;
      overflow: hidden; 
}

// .alice-carousel__stage::-webkit-scrollbar {
//   display: none;
// }

.alice-carousel__prev-btn {
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 27%;
  bottom: 27%;
  float: right;
  color: #09101D;
  font-size: 36px;
  font-weight: bolder;
}
  .alice-carousel__prev-btn [data-area]::after{
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 27%;
    bottom: 27%;
    float: right;
    color: #09101D;
    font-size: 36px;
    font-weight: bolder;
    content: attr(data-area);
}
.alice-carousel__next-btn {
  cursor: pointer;
  position : absolute;
  float: left;
  right: 20px;
  top: 27%;
  bottom: 27%;
  color: #09101D;
  font-size: 36px;
  font-weight: bolder;
}
  .alice-carousel__next-btn [data-area]::after {
    cursor: pointer;
    position : absolute;
    float: left;
    right: 20px;
    top: 27%;
    bottom: 27%;
    color: #09101D;
    font-size: 36px;
    font-weight: bolder;
    content: attr(data-area);
}

.dzu-filename {
  display: inline-block;
  width: 120px;
  color: white;
  position:absolute;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  vertical-align: bottom;
  top: 120px;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  font-family: Roboto;
}

.file-selected {
  color : #09101D;
  text-align: center;
  justify-content: center;
}

// -------- END DRAG & DROP COMPONENT -----------/

// -------- UI LOADING -------------/
svg .cls-1 {
  fill: #000;
  fill-opacity: 0;
  stroke: #000;
  stroke-width: 1px;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

svg .cls-2 {
  fill: #C24428;
  fill-opacity: 0;
  stroke: #C24428;
  stroke-width: 1px;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

// svg {
//   animation: grow 2s 0.8s 1 ease-out forwards;
// }

svg .cls-1,
svg .cls-2 {
  animation: brush 3s 0.8s infinite ease-in forwards;
}

@keyframes brush {
  0% {
    stroke-dashoffset: 440;
    fill-opacity: 0;
  }
  75%{
    stroke-width: 1px;
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
    stroke-width: 0;
    stroke-dashoffset: 0;
  }
}

@keyframes grow{
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.loader-wrap{
	position: fixed;
	left: 0;right: 0;top: 0;bottom: 0;
	margin: auto;
  text-align: center;
  padding: 20%;
}

.loading {
  position: absolute;
	left: 0;right: 0;top: 0;bottom: 0;
	margin: auto;
	height: (4*10px);
	width: (4*80px);
	box-sizing: border-box;
}

.loading-title {
  // text-align: center;
  color: #fff;
  margin: 0;
  font: .8em verdana;
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-bottom: 5px;
}

.loading span {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: .9em;
  height: .9em;
  margin: .20em;
  background: #D11F1F;
  border-radius: .9em;
  animation: loading 1s infinite alternate;
}

.loading span:nth-of-type(2) {
  background: #de4116;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #e55511;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #fa8a04;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #f19842;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #fddc65;
  animation-delay: 1.0s;
}
.loading span:nth-of-type(7) {
  background: #ccc;
  animation-delay: 1.2s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading img {
  width: 50%;
  height: auto;
  text-shadow:  0 0 50px #de4116,
                0 0 100px #de4116,
                0 0 150px #de4116,
                0 0 200px #de4116,
                0 0 250px #de4116;
  animation: animate 2s linear infinite;
  // animation: 3s ease 0s normal forwards 1 fadein;
  // animation-iteration-count: infinite;
}

@keyframes animate{
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

@keyframes fadein{
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.svg-wrapper {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -136px;
}

#carpenter-logo {
  stroke: rgba(255,255,255,0.5);
  stroke-width: 1.25px;
  animation: fadeOut 1s ease 2.75s forwards;
}
// -------- END UI LOADING -------------/

.chip-success {
  background: #49AA4D;
  color: white;
}

.chip-error {
  background: #E93E32;
  color: white;
}

.chip-upload {
  background: #F6BA09;
  color: white;
}


// -------- E-METERAI UI --------/